// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-alltagsgestaltung-aktivierung-js": () => import("./../../../src/pages/angebot/alltagsgestaltung-aktivierung.js" /* webpackChunkName: "component---src-pages-angebot-alltagsgestaltung-aktivierung-js" */),
  "component---src-pages-angebot-altersheim-js": () => import("./../../../src/pages/angebot/altersheim.js" /* webpackChunkName: "component---src-pages-angebot-altersheim-js" */),
  "component---src-pages-angebot-aromapflege-js": () => import("./../../../src/pages/angebot/aromapflege.js" /* webpackChunkName: "component---src-pages-angebot-aromapflege-js" */),
  "component---src-pages-angebot-gedaechtnistraining-js": () => import("./../../../src/pages/angebot/gedaechtnistraining.js" /* webpackChunkName: "component---src-pages-angebot-gedaechtnistraining-js" */),
  "component---src-pages-angebot-kurzaufenthalt-js": () => import("./../../../src/pages/angebot/kurzaufenthalt.js" /* webpackChunkName: "component---src-pages-angebot-kurzaufenthalt-js" */),
  "component---src-pages-angebot-shiatsu-js": () => import("./../../../src/pages/angebot/shiatsu.js" /* webpackChunkName: "component---src-pages-angebot-shiatsu-js" */),
  "component---src-pages-anmeldung-js": () => import("./../../../src/pages/anmeldung.js" /* webpackChunkName: "component---src-pages-anmeldung-js" */),
  "component---src-pages-bannau-light-js": () => import("./../../../src/pages/bannau-light.js" /* webpackChunkName: "component---src-pages-bannau-light-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-en-guete-js": () => import("./../../../src/pages/en-guete.js" /* webpackChunkName: "component---src-pages-en-guete-js" */),
  "component---src-pages-formulare-dokumente-js": () => import("./../../../src/pages/formulare-dokumente.js" /* webpackChunkName: "component---src-pages-formulare-dokumente-js" */),
  "component---src-pages-freiwilligen-arbeit-js": () => import("./../../../src/pages/freiwilligen-arbeit.js" /* webpackChunkName: "component---src-pages-freiwilligen-arbeit-js" */),
  "component---src-pages-geschichte-traegerschaft-js": () => import("./../../../src/pages/geschichte-traegerschaft.js" /* webpackChunkName: "component---src-pages-geschichte-traegerschaft-js" */),
  "component---src-pages-google-bewerten-js": () => import("./../../../src/pages/google-bewerten.js" /* webpackChunkName: "component---src-pages-google-bewerten-js" */),
  "component---src-pages-heimgottesdienst-js": () => import("./../../../src/pages/heimgottesdienst.js" /* webpackChunkName: "component---src-pages-heimgottesdienst-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leitbild-organigramm-js": () => import("./../../../src/pages/leitbild-organigramm.js" /* webpackChunkName: "component---src-pages-leitbild-organigramm-js" */),
  "component---src-pages-leitung-js": () => import("./../../../src/pages/leitung.js" /* webpackChunkName: "component---src-pages-leitung-js" */),
  "component---src-pages-tagebuch-js": () => import("./../../../src/pages/tagebuch.js" /* webpackChunkName: "component---src-pages-tagebuch-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-termine-js": () => import("./../../../src/pages/termine.js" /* webpackChunkName: "component---src-pages-termine-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

