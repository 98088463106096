// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
import "./src/fontfaces.css"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
// mega menu navigation CSS styles
import "./src/megamenu.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import 'focus-within-polyfill'


// Simple React Lightbox
import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import SimpleReactLightbox from 'simple-react-lightbox-pro'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)